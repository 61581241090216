.header-nav-toggle {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
}
.my-burger {
  Result Skip Results Iframe EDIT ON #menu__toggle {
    opacity: 0;
  }
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    left: 0 !important;
  }
  .menu__btn {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #616161;
    transition-duration: 0.25s;
  }
  .menu__btn > span::before {
    content: "";
    top: -8px;
  }
  .menu__btn > span::after {
    content: "";
    top: 8px;
  }
  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #eceff1;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transition-duration: 0.25s;
  }
  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: 0.25s;
  }
  .menu__item:hover {
    background-color: #cfd8dc;
  }
}
.hamburger,
.hamburger-inner {
  display: block;
  pointer-events: none;
}

.hamburger {
  position: relative;
  width: $header-hamburger--size;
  height: $header-hamburger--size;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: $header-hamburger--size;
  height: $header-hamburger--thickness;
  position: absolute;
  background: color-icon(hamburger);
  border-radius: $header-hamburger--radius;

  .invert-color & {
    background: color-icon(hamburger-inverse);
  }
}

.hamburger-inner {
  top: 50%;
  margin-top: -($header-hamburger--thickness * 0.5);
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: -$header-hamburger--distance;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }

  &::after {
    bottom: -$header-hamburger--distance;
    transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      width 0.1s 0.25s ease-in;
  }

  .off-nav-is-active & {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    }

    &::after {
      width: $header-hamburger--size;
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out,
        transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
        width 0.1s ease-out;
    }
  }
}
